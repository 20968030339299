import React, { useState } from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import ReactGA from 'react-ga';

import Navigation from './Navigation';
import { Button, LanguageDropDown } from 'ui';
import { setUserRole } from 'redux/userSlice';
import { useAppDispatch } from 'hooks/useAppDispatch';
import ProfileMenuButton from 'components/header/ProfileMenuButton';
import { useAppSelector } from 'hooks/useAppSelector';

import LogoClient from 'images/main-page/logo-client.svg';
import LoginButton from './LoginButton';
import BurgerMenuButton from './BurgerMenuButton';

const navigationLinks = (t: any) => [
  {
    title: t('hairdresserPage.navigation.faq'),
    href: 'faq',
  },
  {
    title: t('businessPage.buttons.business'),
    href: '/specialist',
  },
];

const MainHeader = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isLoggedIn } = useAppSelector((state) => state.user);

  const [isOpen, setIsOpen] = useState(false);
  const ref = useOnclickOutside(() => {
    setIsOpen(false);
  });

  const handleBook = () => {
    ReactGA.event({
      category: 'User',
      action: 'Clicked Book now button',
      label: 'BotoxHeader',
    });

    dispatch(setUserRole('client'));
    navigate('/category');
  };

  const handleLogin = () => {
    dispatch(setUserRole('client'));
    navigate('/login');
  };

  return (
    <header className='main-page-wrapper sticky left-0 top-0 z-50 max-h-[15vh] w-full bg-[#F6F5F4] px-5 md:px-[50px]'>
      <nav className='mx-auto flex items-center justify-between py-5'>
        <a href='/' className='flex items-center'>
          <img
            src={LogoClient}
            height={40}
            width={86}
            alt='GoTou Logo'
            draggable={false}
          />
        </a>
        <div className='flex items-center gap-5'>
          <div className='hidden lg:block'>
            <Navigation links={navigationLinks(t)} />
          </div>
          <div className='hidden sm:block md:ml-14'>
            <LanguageDropDown />
          </div>
          <div className='flex justify-between gap-4'>
            {!isLoggedIn && (
              <>
                <Button
                  onClick={handleBook}
                  variant='outline-botox'
                  className='hidden !py-2 !text-base2 sm:block'
                >
                  {t('hairdresserPage.buttons.book')}
                </Button>
                <LoginButton />
              </>
            )}
          </div>
          {isLoggedIn && <ProfileMenuButton />}
          <div ref={ref} className='xl:hidden'>
            <BurgerMenuButton setIsOpen={setIsOpen} isOpen={isOpen} />
            <div
              className={`transfrom absolute left-0 right-0 top-0 flex flex-col gap-12 rounded-b-[20px] bg-white px-[10%] pb-12 pt-20 transition-all duration-200 ease-in-out xs:px-[5%] ${
                isOpen
                  ? 'translate-y-0 opacity-100'
                  : 'pointer-events-none -translate-y-10 opacity-0'
              } -z-20`}
            >
              <Navigation
                links={navigationLinks(t)}
                onClick={() => setIsOpen(!isOpen)}
                isVerticalLayout
              />
              <LanguageDropDown disableAbsolutePositioning />
              <div className='flex flex-col gap-4 sm:flex-row '>
                {!isLoggedIn && (
                  <>
                    <Button
                      onClick={handleBook}
                      variant='outline-botox'
                      className='h-12 w-fit !py-2 !text-md2'
                    >
                      {t('hairdresserPage.buttons.book')}
                    </Button>
                    <Button
                      onClick={handleLogin}
                      variant='outline-botox'
                      className='h-12 w-fit !py-2 !text-md2'
                    >
                      {t('button_1')}
                    </Button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default MainHeader;
