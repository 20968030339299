import React, { lazy, Suspense, useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router';
import { Toaster } from 'react-hot-toast';
import ReactGA from 'react-ga';

import { refreshTokens } from 'api/api';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import SupportEmailModal from 'modals/SupportEmailModal';
import {
  BotoxPage,
  MainClientPage,
  MainSpecialistPage,
  PrivacyPolicyPage,
  TermsConditionsPage,
} from 'pages';
import { getTypedStorageItem, setTypedStorageItem } from 'utils/storage';
import { CountryCode } from 'utils/types';
import { logOutUser } from 'redux/actions';
import AcountDeletedModal from 'modals/AcountDeletedModal';
import AppLayout from 'components/Layout/AppLayout';
import LegalLayout from 'components/Layout/LegalLayout';

const ChangesSavedModal = lazy(() => import('modals/ChangesSavedModal'));
const TermsModal = lazy(() => import('modals/TermsModal'));
const ShareReferralModal = lazy(() => import('modals/ShareReferralModal'));
const SpecialistCanceledOrdedModal = lazy(
  () => import('modals/SpecialistCanceledOrdedModal'),
);
const CancelOrderModal = lazy(() => import('modals/CancelOrderModal'));
const RegisterModal = lazy(() => import('modals/RegisterModal'));

if (window.location.hostname !== 'dev.gotou') {
  ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID || '', {
    testMode: true,
  });
  ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID || '', {
    testMode: true,
  });
}

const App = () => {
  const { role } = useAppSelector((state) => state.user);
  const {
    changesSaved,
    specialistCanceledOrder,
    cancelOrder,
    supportEmail,
    terms,
    shareReferral,
    register,
  } = useAppSelector((state) => state.modals);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  useEffect(() => {
    const refreshToken = getTypedStorageItem('gotouRefreshToken');

    if (!refreshToken || role === 'guest' || pathname === '/login') return;
    const fetchTokens = async () => {
      try {
        await refreshTokens();
      } catch (error: any) {
        dispatch(logOutUser());
      }
    };

    fetchTokens();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, role]);

  useEffect(() => {
    const fetchCountry = async () => {
      try {
        const response = await fetch(`https://ipapi.co/country/`);
        if (response.ok) {
          const countryCode = await response.text();
          setTypedStorageItem('country', countryCode as CountryCode);
        }
      } catch (error) {}
    };
    fetchCountry();
  }, []);

  useEffect(() => {
    const startTime = Date.now();

    const sendSessionDuration = () => {
      const endTime = Date.now();
      const sessionDuration = (endTime - startTime) / 1000; // in seconds
      ReactGA.event({
        category: 'User',
        action: 'Session Duration',
        value: sessionDuration,
      });
    };

    window.addEventListener('beforeunload', sendSessionDuration);

    return () => {
      window.removeEventListener('beforeunload', sendSessionDuration);
      sendSessionDuration();
    };
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(search);
    const redirectTo = queryParams.get('redirect_to');

    switch (redirectTo) {
      case 'delete-account':
        navigate('/profile/delete/info');
        break;

      case 'privacy-policy':
        navigate('/legal/privacy-policy');
        break;

      case 'terms-of-condition':
        navigate('/legal/terms-condition');
        break;

      default:
        break;
    }
  }, [search, navigate]);

  return (
    <>
      <Routes>
        <Route path='/' element={<MainClientPage />} />
        <Route path='/botox' element={<BotoxPage />} />
        <Route path='/specialist' element={<MainSpecialistPage />} />
        <Route path='/legal' element={<LegalLayout />}>
          <Route path='privacy-policy' element={<PrivacyPolicyPage />} />
          <Route path='terms-condition' element={<TermsConditionsPage />} />
        </Route>

        <Route path='*' element={<AppLayout />} />
      </Routes>
      <Suspense fallback={<div />}>
        {changesSaved.active && <ChangesSavedModal />}
        {specialistCanceledOrder.active && <SpecialistCanceledOrdedModal />}
        {cancelOrder.active && <CancelOrderModal />}
        {supportEmail.active && <SupportEmailModal />}
        {terms.active && <TermsModal />}
        {shareReferral.active && <ShareReferralModal />}
        {register.active && <RegisterModal />}
        <AcountDeletedModal />
      </Suspense>
      <Toaster
        toastOptions={{
          error: {
            duration: 2000,
          },
        }}
      />
    </>
  );
};

export default App;
