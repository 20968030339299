import React from 'react';
import { Currency } from 'utils/types';

type CurrencyPickerProps = {
  label: string;
  value: Currency;
  setCurrencyInput: (value: Currency) => void;
};

const CurrencyPicker = ({
  label,
  value,
  setCurrencyInput,
}: CurrencyPickerProps) => {
  return (
    <div className='flex items-center justify-end'>
      <div className='relative mt-4 w-44 rounded-lg border border-gray-3 p-2 transition-all hover:border-accent'>
        <label className='absolute -top-2 bg-white text-xs text-gray-2'>
          {label}
        </label>
        <select
          name='currency'
          id='currency'
          className='w-40 cursor-pointer bg-white outline-none'
          value={value}
          onChange={(e) => setCurrencyInput(e.target.value as Currency)}
        >
          <option value='USD'>USD $</option>
          <option value='EUR'>EUR €</option>
          <option value='UAH'>UAH ₴</option>
          <option value='CHF'>CHF ₣</option>
        </select>
      </div>
    </div>
  );
};

export default CurrencyPicker;
