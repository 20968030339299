import React, { useCallback, useMemo } from 'react';
import SectionLabel from './SectionLabel';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'hooks/useAppSelector';
import { convertMsToTime } from 'utils/time';
import ReactDatePicker from 'react-datepicker';
import { AnimatePresence } from 'framer-motion';
import { useAppDispatch } from 'hooks/useAppDispatch';
import CalendarIcon from 'images/emoji/Calendar days.svg';
import {
  setDateStamp,
  setIsTimeSelected,
  setOrderDate,
  setHoursTimeStamp,
  setTimeSlotId,
} from 'redux/createOrderPageSlice';
import { TimeSlotsList, HeaderDatePicker } from 'ui';
import { SpecialistWithTimeSlots } from 'utils/types';
import { useApiRequest } from 'hooks/useApiRequest';
import { BASE_URL } from 'api/api';
import SpecialistOrderList from './SpecialistOrderList';

type DateSectionProps = {
  toggleDatePicker: () => void;
};

const nextMonth = new Date(
  new Date().getFullYear(),
  new Date().getMonth() + 2,
  0,
);

const DateSection = ({ toggleDatePicker }: DateSectionProps) => {
  const { t } = useTranslation();

  const {
    chosenServices,
    isTimeSelected,
    dateStamp,
    month,
    year,
    isCalendarOpen,
  } = useAppSelector((state) => state.createOrderPage);
  const { role } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();

  const dateFrom = `${year}-${month}-01`;
  const dateTo = `${year}-${month}-${new Date(year, +month, 0).getDate()}`;

  const { data: specialistTimeSlots } = useApiRequest<SpecialistWithTimeSlots>({
    conditionToFetch: !!chosenServices[0]?.stringId,
    endpoint: `${BASE_URL}time-slot/by-serviceid/${chosenServices[0]?.stringId}/date-range?dateFrom=${dateFrom}&dateTo=${dateTo}`,
    deps: [chosenServices[0]?.stringId, month],
  });

  const getTimeSlotsForSelectedDay = useCallback(() => {
    if (!specialistTimeSlots || specialistTimeSlots.length === 0) {
      return [];
    }

    // Format the dateStamp as a date without time (year, month, day)
    const targetDate = dateStamp
      ? new Date(dateStamp).setHours(0, 0, 0, 0)
      : null;

    const now = Date.now();

    // Filter and map the timeslots based on the date comparison
    return specialistTimeSlots[0].timeSlots.filter((timeSlot) => {
      const timeSlotDate = new Date(timeSlot.startTime).setHours(0, 0, 0, 0);
      const timeSlotTime = new Date(timeSlot.startTime).getTime();

      return timeSlotDate === targetDate && timeSlotTime > now;
    });
  }, [specialistTimeSlots, dateStamp]);

  const handleSelectDate = (date: Date | null) => {
    dispatch(setDateStamp(date!?.getTime()));
    dispatch(setHoursTimeStamp(null));
    dispatch(setIsTimeSelected(false));
  };

  const handleSelectTimeSlot = (time: Date, timeSlotId: number) => {
    dispatch(setTimeSlotId(timeSlotId));
    dispatch(setDateStamp(time.getTime()));
    dispatch(setHoursTimeStamp(time.getTime()));
    dispatch(setIsTimeSelected(true));
  };

  const daysWithTimeSlots = useMemo(() => {
    if (!specialistTimeSlots) {
      return [];
    }

    const now = new Date();

    const availableDates = specialistTimeSlots[0].timeSlots
      .filter((timeSlot) => {
        const timeSlotDate = new Date(timeSlot.startTime);
        // Check if the time slot is in the future
        return timeSlotDate > now;
      })
      .map((timeSlot) => {
        const date = new Date(timeSlot.startTime);
        return new Date(date.getFullYear(), date.getMonth(), date.getDate());
      });

    const uniqueAvailableDates = Array.from(
      new Set(availableDates.map((date) => date.getTime())),
    );

    return uniqueAvailableDates.map((timestamp) =>
      new Date(timestamp).getDate(),
    );
  }, [specialistTimeSlots]);

  const description =
    dateStamp && chosenServices[0]
      ? `${new Date(dateStamp).toLocaleDateString([], {
          month: 'short',
          weekday: 'short',
          day: 'numeric',
          ...(isTimeSelected && {
            hour: 'numeric',
            minute: 'numeric',
          }),
        })} - ${
          isTimeSelected
            ? convertMsToTime(dateStamp + chosenServices[0]?.gssDto?.duration)
            : ''
        }`
      : '';

  return (
    <>
      <SectionLabel
        title={t('create_order.date_and_time')}
        description={description}
        icon={CalendarIcon}
        enable={isCalendarOpen}
        onClick={toggleDatePicker}
      />
      <AnimatePresence>
        {isCalendarOpen && (
          <>
            <li className=' flex flex-col items-center px-5'>
              <ReactDatePicker
                dateFormat='MMMM d, yyyy h:mm aa'
                filterDate={(date) =>
                  daysWithTimeSlots.includes(date.getDate()) || false
                }
                inline
                maxDate={nextMonth}
                minDate={new Date()}
                onChange={handleSelectDate}
                onMonthChange={(date) => dispatch(setOrderDate(date.getTime()))}
                renderCustomHeader={HeaderDatePicker}
                selected={dateStamp ? new Date(dateStamp) : null}
                wrapperClassName='transition-all focus:outline-none '
                shouldCloseOnSelect={false}
              />
              {dateStamp && (
                <TimeSlotsList
                  timeSlots={getTimeSlotsForSelectedDay()}
                  handleSelectTime={handleSelectTimeSlot}
                />
              )}
            </li>
            {role === 'specialist' && dateStamp && (
              <SpecialistOrderList dateStamp={dateStamp} />
            )}
          </>
        )}
      </AnimatePresence>
    </>
  );
};

export default DateSection;
