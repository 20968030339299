import React from 'react';
import { Wrapper } from 'ui';
import BG from 'images/backgrounds/bg-trial.jpg';
import { useTranslation } from 'react-i18next';
import { IoCheckmark } from 'react-icons/io5';
import StripePricingTable from 'components/Subscription/StripePricingTable';

const TrialPage = () => {
  const { t } = useTranslation();

  return (
    <Wrapper
      noHorizontalPadding
      flexColumnSpaceBetween
      className='sm:!min-w-[650px]'
    >
      <div>
        <img
          src={BG}
          alt='Background'
          height={228}
          width={428}
          className='mb-5 w-full'
        />
        <div className='mb-10 space-y-3 px-4'>
          <h3 className='text-center text-xl2 font-medium'>
            {t('trialPage.title')}
          </h3>
          <p className='text-center text-md2 text-gray-9'>
            {t('trialPage.access')}{' '}
            <span className='whitespace-nowrap rounded-[20px] bg-[#FFDA57EB] px-2 py-1 font-medium text-primary'>
              {t('trialPage.freeTrial')}
            </span>
            <br />
            {t('trialPage.withoutRestrictions')}
          </p>
        </div>
      </div>
      <StripePricingTable />
      <ul className='mx-auto mb-10 max-w-[380px] space-y-3 px-4'>
        {(t('trialPage.benefits', { returnObjects: true }) as string[]).map(
          (benefit: string, i: number) => (
            <li className='flex items-center justify-between' key={i}>
              <IoCheckmark size={25} className='text-accent' />
              <p className='text-right text-md2'>{benefit}</p>
            </li>
          ),
        )}
      </ul>
      <p className='text-center text-sm text-gray-8'>
        {t('trialPage.cancelAnyTime')}
      </p>
    </Wrapper>
  );
};

export default TrialPage;
