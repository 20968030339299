import React from 'react';
import { useTranslation } from 'react-i18next';
import { Radio } from 'ui';
import ComputerIcon from 'images/emoji/desktop_computer.png';

type ProvidedSectionProps = {
  isOnline: boolean;
  setIsOnline: (value: boolean) => void;
};

const ProvidedSection = ({ isOnline, setIsOnline }: ProvidedSectionProps) => {
  const { t } = useTranslation();
  return (
    <div className='flex justify-between border-b border-gray-10'>
      <div className='flex items-start justify-start gap-2'>
        <img
          src={ComputerIcon}
          alt='icon'
          width={24}
          height={24}
          className='xs:hidden'
        />
        <p>{t('add_service_page.service_provided')}</p>
      </div>
      <div className='flex flex-col gap-3'>
        <Radio
          required
          name='Online'
          label={t('specialist.service_online')}
          checked={isOnline}
          onChange={() => setIsOnline(!isOnline)}
        />
        <Radio
          required
          name='Online'
          label={t('specialist.service_offline')}
          checked={!isOnline}
          onChange={() => setIsOnline(!isOnline)}
        />
      </div>
    </div>
  );
};

export default ProvidedSection;
