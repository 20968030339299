import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Currency, Order, Service, SpecialistProfileDto } from 'utils/types';
import { logOutUser } from './actions';

type CreateOrderPageState = {
  dateStamp: number | null;
  hoursTimeStamp: number | null;
  day: number;
  month: string;
  year: number;
  customerName: string;
  chosenServices: Service[];
  isOpenSelectCustomer: boolean;
  showSheetOfServices: boolean;
  isCalendarOpen: boolean;
  phoneNumber: string;
  clientId: string;
  isTimeSelected: boolean;
  orderDto: {
    clientId: string;
    specialistId: string;
    price: number;
    serviceId: string;
    duration: number;
    ts: number;
    currency: Currency;
    role: 'client' | 'specialist' | 'guest';
  } | null;
  specialist: SpecialistProfileDto;
  timeSlotId: number;
  specialistOrders: {
    dur: number;
    id: number;
    idStr: string;
    serviceName: string;
    timeOfOrder: number;
    price: number;
    currency: Currency;
    clientName: string;
    clientPhotoUrl: string;
    clientPhoneNumber: string;
  }[];
};

const initialState: CreateOrderPageState = {
  dateStamp: null,
  hoursTimeStamp: null,
  customerName: '',
  day: new Date().getDate(),
  month: String(new Date().getMonth() + 1).padStart(2, '0'),
  year: new Date().getFullYear(),
  chosenServices: [],
  isOpenSelectCustomer: false,
  showSheetOfServices: false,
  isCalendarOpen: false,
  phoneNumber: '',
  clientId: '',
  isTimeSelected: false,
  orderDto: null,
  specialist: {
    address: '',
    createdAt: 0,
    firstName: '',
    id: 0,
    lastName: '',
    picsCnt: 0,
    profilePhoto: '',
    rating: 0,
    since: 0,
    stringId: '',
    reviewsCnt: 0,
    descr: '',
    insta: '',
  },
  timeSlotId: 0,
  specialistOrders: [],
};

const createOrderPageSlice = createSlice({
  name: 'createOrderPage',
  initialState,
  reducers: {
    setDateStamp: (state, { payload }: PayloadAction<number>) => {
      state.dateStamp = payload;
    },
    setHoursTimeStamp: (state, { payload }: PayloadAction<number | null>) => {
      state.hoursTimeStamp = payload;
    },
    setTimeSlotId: (state, { payload }: PayloadAction<number>) => {
      state.timeSlotId = payload;
    },
    setCustomerName: (state, { payload }: PayloadAction<string>) => {
      state.customerName = payload;
    },
    addChosenService: (state, { payload }: PayloadAction<Service>) => {
      state.chosenServices.push(payload);
    },
    removeChosenService: (state, { payload }: PayloadAction<Service['id']>) => {
      state.chosenServices = state.chosenServices.filter(
        (service) => service.id !== payload,
      );
    },
    setChoosenServices: (state, { payload }: PayloadAction<Service[]>) => {
      state.chosenServices = payload;
    },
    clearChosenServices: (state) => {
      state.chosenServices = [];
    },
    setIsOpenSelectCustomer: (state, { payload }: PayloadAction<boolean>) => {
      state.isOpenSelectCustomer = payload;
    },
    setShowSheetOfServices: (state, { payload }: PayloadAction<boolean>) => {
      state.showSheetOfServices = payload;
    },
    setIsCalendarOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isCalendarOpen = payload;
    },
    setPhoneNumber: (state, { payload }: PayloadAction<string>) => {
      state.phoneNumber = payload;
    },
    setOrderDate: (state, { payload }: PayloadAction<number>) => {
      // payload - timestamp
      const month = new Date(payload).getMonth() + 1;

      state.day = new Date(payload).getDate();
      state.month = String(month).padStart(2, '0');
      state.year = new Date(payload).getFullYear();
    },
    setSpecialistDto: (
      state,
      { payload }: PayloadAction<SpecialistProfileDto>,
    ) => {
      state.specialist = payload;
    },
    addCustomer: (
      state,
      {
        payload,
      }: PayloadAction<{
        name: string;
        surname: string;
        phoneNumber: string;
        clientId: string;
      }>,
    ) => {
      state.customerName = `${payload.name} ${payload.surname}`;
      state.phoneNumber = payload.phoneNumber;
      state.clientId = payload.clientId;
    },
    setIsTimeSelected: (state, { payload }: PayloadAction<boolean>) => {
      state.isTimeSelected = payload;
    },

    setOrderDto: (
      state,
      { payload }: PayloadAction<CreateOrderPageState['orderDto']>,
    ) => {
      state.orderDto = payload;
    },

    setSpecialistOrders: (state, { payload }: PayloadAction<Order[]>) => {
      const orderDays = payload.map((order) => order.orders).flat();
      state.specialistOrders = orderDays;
    },

    resetState: () => initialState,
  },
  extraReducers(builder) {
    builder.addCase(logOutUser, () => initialState);
  },
});

export const {
  setDateStamp,
  setHoursTimeStamp,
  setCustomerName,
  addChosenService,
  removeChosenService,
  clearChosenServices,
  setIsOpenSelectCustomer,
  setShowSheetOfServices,
  setIsCalendarOpen,
  setPhoneNumber,
  addCustomer,
  setChoosenServices,
  resetState,
  setIsTimeSelected,
  setOrderDto,
  setOrderDate,
  setSpecialistDto,
  setTimeSlotId,
  setSpecialistOrders,
} = createOrderPageSlice.actions;

export default createOrderPageSlice.reducer;
