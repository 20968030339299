import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { BASE_URL } from 'api/api';
import { useApiRequest } from 'hooks/useApiRequest';
import { useAppSelector } from 'hooks/useAppSelector';
import { Order } from 'utils/types';
import { THIRTY_DAYS_IN_MS } from 'utils/time';

import OrderCardWithAvatar from 'components/Calendar/OrderCardWithAvatar';
import { HeaderDatePicker, SectionHeader, Wrapper } from 'ui';

import { FaCirclePlus } from 'react-icons/fa6';

const CalendarPage = () => {
  const { t } = useTranslation();

  const [dateStamp, setDateStamp] = useState(Date.now());
  const [currMonth, setCurrMonth] = useState(new Date().getMonth() + 1);
  const { uid } = useAppSelector((state) => state.user);
  const navigate = useNavigate();

  const currentDay = new Date(dateStamp).getDate();
  const currentYear = new Date(dateStamp).getFullYear();
  const handleChangeDate = (date: Date) => {
    setDateStamp(date.getTime());
  };
  const currentData = new Date(dateStamp).toLocaleDateString([], {
    dateStyle: 'long',
  });

  const { data: orderDays } = useApiRequest<Order[]>({
    endpoint: `${BASE_URL}orders/shortInfo/${uid}/${currMonth}/${currentYear}`,
    destructuringDataType: 'calendar',
    deps: [currMonth],
  });

  const ordersOnSelectedDay = orderDays?.filter(
    (orderDay) => new Date(orderDay.day).getDate() === currentDay,
  );

  const orderDates = orderDays?.map((orderDay) => new Date(orderDay.day));

  return (
    <Wrapper>
      <SectionHeader title={t('menu.calendar')} />
      <div className='mt-5 flex w-full justify-center'>
        <DatePicker
          selected={new Date(dateStamp)}
          onChange={handleChangeDate}
          dateFormat='MMMM d, yyyy h:mm aa'
          minDate={new Date(2024, 1)}
          maxDate={new Date(Date.now() + THIRTY_DAYS_IN_MS)}
          inline
          highlightDates={orderDates}
          renderCustomHeader={(params) => (
            <HeaderDatePicker {...params} setCurrMonth={setCurrMonth} />
          )}
        />
      </div>

      {ordersOnSelectedDay?.length === 0 && (
        <p className='text-sm text-secondary'>{currentData}</p>
      )}

      <div
        className={`mt-3 max-h-56 overflow-y-auto rounded-[10px] border border-accent ${
          ordersOnSelectedDay?.length === 0 ? 'px-4 py-6' : 'px-3 pb-7 pt-4'
        } `}
      >
        {ordersOnSelectedDay && ordersOnSelectedDay?.length > 0 && (
          <p className='mb-2 text-sm text-gray-2'>{currentData}</p>
        )}

        {ordersOnSelectedDay?.length === 0 && (
          <div className='flex items-center justify-between'>
            <p className='text-sm text-secondary'>{t('calendar.add_order')}</p>
            <FaCirclePlus
              className='cursor-pointer rounded-full bg-white  text-accent shadow-md transition-all hover:shadow-lg '
              onClick={() => navigate('/specialist/create-order')}
              size={43}
            />
          </div>
        )}
        {ordersOnSelectedDay?.map(({ orders }) =>
          orders.map((order) => (
            <OrderCardWithAvatar key={order.id} order={order} />
          )),
        )}
      </div>
      {ordersOnSelectedDay && ordersOnSelectedDay?.length > 0 && (
        <div className='mt-4 flex items-center justify-between'>
          <p className='text-sm text-secondary'>{t('calendar.add_order')}</p>
          <FaCirclePlus
            className='cursor-pointer rounded-full bg-white  text-accent shadow-md transition-all hover:shadow-lg '
            onClick={() => navigate('/specialist/create-order')}
            size={43}
          />
        </div>
      )}
    </Wrapper>
  );
};

export default CalendarPage;
