import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';
import { useLocation, useNavigate, useParams } from 'react-router';

import { useApiRequest } from 'hooks/useApiRequest';
import { useAppSelector } from 'hooks/useAppSelector';
import useCounter from 'hooks/useCounter';

import {
  BASE_URL,
  fetchRequestBoolean,
  updateSpecialistService,
} from 'api/api';

import { Currency, Service } from 'utils/types';
import { convertHoursToMs, convertMinToMs, convertMsToMin } from 'utils/time';

import {
  ActionButtons,
  Autocomplete,
  CounterInput,
  CurrencyPicker,
  SectionHeader,
  toastWithDismiss,
  Wrapper,
} from 'ui';

import MoneyIcon from 'images/emoji/Cash.svg';
import TypeSection from 'components/ManageService/TypeSection';
import ProvidedSection from 'components/ManageService/ProvidedSection';
import DurationSection from 'components/ManageService/DurationSection';

const MAX_PRICE = 5000;
const MIN_PRICE = 0;

const detectLanguage = async (serviceName: string) => {
  const apiKey = process.env.REACT_APP_GOOGLE_TRANSLATE_API_KEY;
  const url = `https://translation.googleapis.com/language/translate/v2/detect?key=${apiKey}&q=${encodeURIComponent(
    serviceName,
  )}`;

  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) return '';

    const data = await response.json();

    const detectedLanguage = data.data.detections[0][0].language || '';
    return detectedLanguage;
  } catch (error) {
    return '';
  }
};

const ManageServicePage = () => {
  const { t } = useTranslation();

  const { services: specServices } = useAppSelector(
    (state) => state.specialistServices,
  );
  const { uid, currency } = useAppSelector((state) => state.user);
  const navigate = useNavigate();
  const { state: routeState } = useLocation();
  const isStateString = typeof routeState === 'string';
  const { id } = useParams();
  const priceCounter = useCounter(
    isStateString ? 20 : routeState?.price,
    10,
    MAX_PRICE,
    MIN_PRICE,
  );
  const { data: fetchedServices } = useApiRequest<Service[]>({
    endpoint: `${BASE_URL}services?subcategoryId=${id}`,
    destructuringDataType: 'services',
  });

  const [isLoading, setIsLoading] = useState(false);
  const [serviceName, setServiceName] = useState(
    isStateString ? '' : routeState?.serviceName,
  );
  const [hours, setHours] = useState(
    isStateString ? 0 : Math.floor(convertMsToMin(routeState?.duration) / 60),
  );
  const [minutes, setMinutes] = useState(
    isStateString ? 0 : convertMsToMin(routeState?.duration) % 60,
  );
  const [currencyInput, setCurrencyInput] = useState<Currency>(
    isStateString || !routeState?.currency ? currency : routeState?.currency,
  );
  const [isGroup, setIsGroup] = useState(
    isStateString ? false : !!routeState?.isGroup,
  );
  const [isOnline, setIsOnline] = useState(
    isStateString ? false : !!routeState?.isOnline,
  );

  const serviceOptions = fetchedServices
    ?.filter((service) =>
      specServices.some((specService) => specService.name === service.name),
    )
    .map((service) => service.name);

  const handleCreateService = async (
    event: React.FormEvent<HTMLFormElement>,
  ) => {
    event.preventDefault();

    if (!serviceName) {
      toastWithDismiss(t('enter_service_name'), 'error');
      return;
    }

    if (
      specServices.some(
        (service) =>
          service.name === serviceName &&
          service.stringId !== routeState?.gserviceIdStr,
      )
    ) {
      toastWithDismiss(t('service_exists'), 'error');
      return;
    }

    if (serviceName.length > 60) {
      toastWithDismiss(t('name_limit'), 'error');
      return;
    }
    if (minutes === 0 && hours === 0) {
      toastWithDismiss(t('enter_duration'), 'error');
      return;
    }

    if (priceCounter.value < MIN_PRICE) {
      toastWithDismiss(t('enter_valid_price'), 'error');
      return;
    }

    setIsLoading(true);

    const language = await detectLanguage(serviceName);

    const serviceData = {
      name: serviceName,
      price: priceCounter.value,
      duration: convertHoursToMs(hours) + convertMinToMs(minutes),
      currency: currencyInput,
      subcategoryId: id!,
      language,
      isOnline,
      isGroup,
    };

    if (!isStateString) {
      const response = await updateSpecialistService(
        uid,
        routeState?.gserviceIdStr,
        {
          ...serviceData,
          serviceId: routeState?.gserviceIdStr,
        },
      );
      setIsLoading(false);

      if (response) {
        toast.dismiss();
        toastWithDismiss(t('service_updated_successfully'), 'success');
      } else {
        toastWithDismiss(t('something_wrong'), 'error');
      }
      return;
    }

    if (id) {
      const response = await fetchRequestBoolean('services', 'POST', {
        ...serviceData,
        specialistId: uid,
      });
      setIsLoading(false);
      if (response) {
        toast.success(t('service_created'));
        navigate(`/specialist/services`);
      }
    }
  };

  const handleChangeInput = (value: string) => {
    if (+value >= MIN_PRICE && +value <= MAX_PRICE) {
      priceCounter.setValue(+value);
    }
  };

  useEffect(() => {
    if (!routeState) {
      navigate('/specialist/services');
    }
  }, [routeState, navigate]);

  return (
    <Wrapper flexColumnSpaceBetween>
      <form
        id='createService'
        className='mb-5 flex flex-col gap-8'
        onSubmit={handleCreateService}
      >
        <SectionHeader
          title={isStateString ? routeState : routeState?.serviceName}
          subtitle={isStateString ? t('create_service') : t('edit_service')}
        />
        <Autocomplete
          options={serviceOptions || []}
          inputValue={serviceName}
          setInputValue={setServiceName}
        />
        <ProvidedSection isOnline={isOnline} setIsOnline={setIsOnline} />
        <TypeSection isGroup={isGroup} setIsGroup={setIsGroup} />
        <CounterInput
          counter={priceCounter}
          label={`${t('add_service_page.price')}, ${currencyInput}`}
          onChange={handleChangeInput}
          icon={MoneyIcon}
        />
        <div>
          <DurationSection
            hours={hours}
            minutes={minutes}
            setHours={setHours}
            setMinutes={setMinutes}
          />
          <CurrencyPicker
            label={t('add_service_page.currency')}
            value={currencyInput}
            setCurrencyInput={setCurrencyInput}
          />
        </div>
      </form>
      <ActionButtons
        handleCancelClick={() => navigate(-1)}
        form='createService'
        isLoading={isLoading}
      />
    </Wrapper>
  );
};

export default ManageServicePage;
