import React from 'react';
import { CgMenuRightAlt } from 'react-icons/cg';
import { IoCloseOutline } from 'react-icons/io5';
type BurgerMenuButtonProps = {
  setIsOpen: (state: boolean) => void;
  isOpen: boolean;
};

const BurgerMenuButton = ({ setIsOpen, isOpen }: BurgerMenuButtonProps) => {
  return isOpen ? (
    <IoCloseOutline
      size={40}
      className='-mr-1 cursor-pointer rounded-md p-1 transition-all hover:text-accent xl:hidden'
      onClick={() => setIsOpen(!isOpen)}
    />
  ) : (
    <CgMenuRightAlt
      size={30}
      className='scale-x-125 cursor-pointer rounded-md p-1 transition-all hover:text-accent xl:hidden'
      onClick={() => setIsOpen(!isOpen)}
    />
  );
};

export default BurgerMenuButton;
