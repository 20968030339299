import React, { useState } from 'react';
import { Button } from 'ui';
import HeroImg from 'images/main-page/photo_header.webp';
import HeroImgHair from 'images/main-page/beauty-girl-long-shiny-wavy-hair.webp';
import { motion } from 'framer-motion';
import { useLocation, useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import ReactGA from 'react-ga';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { setUserRole } from 'redux/userSlice';
import { useAppSelector } from 'hooks/useAppSelector';
import AcceptToast from 'modals/AcceptToast';
import { logOut } from 'api/login';
import toast from 'react-hot-toast';

const HeroSection = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const { isLoggedIn, role, uid } = useAppSelector((state) => state.user);

  const handleImageLoad = () => {
    setIsImageLoaded(true);
  };

  const handleLogin = () => {
    ReactGA.event({
      category: 'User',
      action: 'Clicked Book an appointment Button',
      label: 'Hair Issue Section',
    });

    if (!isLoggedIn || role !== 'specialist') {
      dispatch(setUserRole('client'));
      navigate('/category');
    } else {
      AcceptToast({
        saveButtonText: t('menu.logout'),
        cancelButtonText: t('client.remove_favorite.no'),
        text: t('message.login_specialist'),
        handleSaveClick: async () => {
          await logOut(
            uid,
            role as 'client' | 'specialist',
            dispatch,
            navigate,
          );

          toast.dismiss();
        },
        duration: 100000,
      });
    }
  };

  const { pathname } = useLocation();
  const isBotoxPage = pathname.includes('botox');

  return (
    <section className='main-page-wrapper max-w-[1440px] md:mt-10'>
      <div className='mx-auto flex max-w-fit flex-col items-center md:max-w-none md:flex-row md:justify-center lg:gap-5'>
        {isImageLoaded && (
          <>
            <motion.h1
              initial={{ x: '-10vw' }}
              animate={{ x: 0 }}
              transition={{ type: 'spring', stiffness: 60, delay: 0.2 }}
              className='mx-auto flex-1 self-end text-center font-sans text-[3rem] italic leading-[4.1rem] md:self-center md:text-right md:text-[3.0625rem] md:leading-[4.078125rem] xs:text-[2.6rem]'
            >
              <span>
                {isBotoxPage ? 'BOTOX®' : t('hairdresserPage.hero.title_1')}
              </span>
              <p className='text-[2.1875rem] leading-[3.28125rem] text-accent lg:text-[2.8125rem] lg:leading-[4.21875rem]'>
                {isBotoxPage ? 'at home' : t('hairdresserPage.hero.title_1-1')}
              </p>
            </motion.h1>
          </>
        )}
        <img
          src={isBotoxPage ? HeroImg : HeroImgHair}
          alt='Beautiful models'
          width={387}
          height={218}
          onLoad={handleImageLoad}
          className='flex-2 aspect-[16/9] max-h-[285px] w-3/4 rounded-br-[150px] rounded-tl-[150px] object-contain md:mx-3 md:max-h-none md:w-[40%]'
        />
        {isImageLoaded && (
          <>
            <motion.h1
              initial={{ x: '10vw' }}
              animate={{ x: 0 }}
              transition={{ type: 'spring', stiffness: 60, delay: 0.2 }}
              className='mx-auto inline-flex flex-1 self-start font-sans text-[3rem] italic leading-[4.2rem] md:m-0 md:block md:self-end lg:text-[4.0625rem] lg:leading-[5.078125rem] xs:text-[2.6rem]'
            >
              <p className='mt-3 text-[2.1875rem] leading-[3.28125rem] text-accent md:mt-0 lg:text-[2.8125rem] lg:leading-[4.21875rem]'>
                {isBotoxPage ? 'in' : t('hairdresserPage.hero.title_2-2')}
              </p>
              <span className='ml-3 md:ml-0'>
                {isBotoxPage
                  ? 'San Francisco'
                  : t('hairdresserPage.hero.title_2')}
              </span>
            </motion.h1>
          </>
        )}
      </div>
      <div className='flex flex-col items-center gap-5 md:mt-6'>
        <p className='max-w-[80%] text-center font-sans text-md2 md:max-w-[600px] md:text-[24px] md:leading-[36px]'>
          {isBotoxPage
            ? 'We are a San Francisco based GotoU app that helps you find a licensed botox specialist'
            : t('hairdresserPage.hero.description')}
        </p>
        <Button variant='outline-botox' onClick={handleLogin}>
          {t('hairdresserPage.buttons.book-appointment')}
        </Button>
      </div>
    </section>
  );
};

export default HeroSection;
