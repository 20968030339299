import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'hooks/useAppSelector';
import { formatCurrency } from 'utils/validations';
import { OrderServiceLabel } from 'ui';

import MoneyIcon from 'images/emoji/Price.svg';

const PriceSection = () => {
  const { chosenServices } = useAppSelector((state) => state.createOrderPage);
  const { t } = useTranslation();
  return (
    <li className='flex items-center justify-between px-5 pt-6'>
      <OrderServiceLabel icon={MoneyIcon} text={t('create_order.price')} />
      <p className='text-secondary'>
        {chosenServices.length > 0
          ? `${formatCurrency(
              chosenServices[0].gssDto.currency,
              chosenServices.reduce(
                (total, service) => total + service.gssDto.price,
                0,
              ),
            )}`
          : t('create_order.select_service')}
      </p>
    </li>
  );
};

export default PriceSection;
