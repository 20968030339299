import React from 'react';
import { useTranslation } from 'react-i18next';
import { Radio } from 'ui';
import CoupleIcon from 'images/emoji/couple.png';

type TypeSectionProps = {
  isGroup: boolean;
  setIsGroup: (value: boolean) => void;
};

const TypeSection = ({ isGroup, setIsGroup }: TypeSectionProps) => {
  const { t } = useTranslation();
  return (
    <div className='flex justify-between border-b border-gray-10'>
      <div className='flex items-start justify-start gap-2 '>
        <img
          src={CoupleIcon}
          alt='icon'
          width={24}
          height={24}
          className='xs:hidden'
        />
        <p>{t('add_service_page.service_type')}</p>
      </div>
      <div className='flex flex-col gap-3'>
        <Radio
          required
          name='Group'
          label={t('specialist.service_group')}
          checked={isGroup}
          onChange={() => setIsGroup(!isGroup)}
        />
        <Radio
          required
          name='Group'
          label={t('specialist.service_individual')}
          checked={!isGroup}
          onChange={() => setIsGroup(!isGroup)}
        />
      </div>
    </div>
  );
};

export default TypeSection;
