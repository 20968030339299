import React from 'react';
import { useMediaQuery } from 'react-responsive';
import AppRoutes from 'AppRoutes';
import Header from 'components/header/Header';
import bgImg from 'images/backgrounds/bg2.webp';

const AppLayout = () => {
  const isSmallScreen = useMediaQuery({ query: '(max-width: 640px)' });

  return (
    <>
      <Header />
      {isSmallScreen ? (
        <AppRoutes />
      ) : (
        <div
          className='flex min-h-screen justify-center bg-cover bg-no-repeat'
          style={{ backgroundImage: `url(${bgImg})` }}
        >
          <div className='-mt-[80px] max-h-fit rounded-[30px] bg-white/30 px-14 py-5 backdrop-blur-md sm:scale-[0.78]'>
            <AppRoutes />
          </div>
        </div>
      )}
    </>
  );
};

export default AppLayout;
