import React, { useEffect, useState } from 'react';
import { motion, Variants } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { getTypedStorageItem } from 'utils/storage';
import { useAppSelector } from 'hooks/useAppSelector';
import { TimeSpecificOrderButton } from 'ui';
import { useApiRequest } from 'hooks/useApiRequest';
import { Order } from 'utils/types';
import { BASE_URL } from 'api/api';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { setSpecialistOrders } from 'redux/createOrderPageSlice';

type SpecialistOrderListProps = {
  dateStamp: number;
};

const variants: Variants = {
  open: {
    opacity: 1,
    height: 'auto',
    type: 'tween',
  },
  closed: { opacity: 0, height: 0, type: 'tween' },
};

const SpecialistOrderList = ({ dateStamp }: SpecialistOrderListProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { uid } = useAppSelector((state) => state.user);
  const { specialistOrders, month, year, day } = useAppSelector(
    (state) => state.createOrderPage,
  );
  const [isAnimating, setIsAnimating] = useState(false);
  const locale = getTypedStorageItem('selectedLanguage') || 'en';

  const { data: todayOrders } = useApiRequest<Order[]>({
    endpoint: `${BASE_URL}orders/shortInfo/${uid}/${day}/${month}/${year}`,
    destructuringDataType: 'calendar',
    deps: [day],
  });

  useEffect(() => {
    if (todayOrders) dispatch(setSpecialistOrders(todayOrders));
  }, [dispatch, todayOrders]);

  return (
    <motion.ul
      className={`my-4 flex max-h-[30vh] flex-col gap-2 ${
        isAnimating ? 'overflow-hidden' : 'overflow-y-auto'
      } px-5 pr-2`}
      variants={variants}
      initial='closed'
      animate={specialistOrders.length > 0 ? 'open' : 'closed'}
      exit='closed'
      onAnimationStart={() => setIsAnimating(true)}
      onAnimationComplete={() => setIsAnimating(false)}
    >
      <li>
        <p className='text-sm text-secondary'>
          {t('orders_on')}{' '}
          {new Date(dateStamp).toLocaleString(locale, {
            weekday: 'long',
            day: 'numeric',
          })}
        </p>
      </li>
      {specialistOrders?.map((order) => (
        <TimeSpecificOrderButton
          url={`/specialist/orders/${order.idStr}`}
          order={order}
          key={order.idStr}
        />
      ))}
    </motion.ul>
  );
};

export default SpecialistOrderList;
