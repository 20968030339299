import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';
import { FaInstagram } from 'react-icons/fa6';

import { useApiRequest } from 'hooks/useApiRequest';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';

import { Service, SpecialistProfileDto } from 'utils/types';
import { removeTypedStorageItem } from 'utils/storage';

import { BASE_URL, createOrder, fetchRequestBoolean } from 'api/api';

import {
  addChosenService,
  clearChosenServices,
  resetState,
  setIsCalendarOpen,
  setOrderDto,
  setSpecialistDto,
} from 'redux/createOrderPageSlice';
import { toggleModal } from 'redux/modalSlice';
import { logOutUser } from 'redux/actions';

import {
  Button,
  OrderServiceForm,
  SectionHeader,
  UserAvatar,
  SpecialistStats,
  toastWithDismiss,
  Wrapper,
} from 'ui';

import RateSpecialistModal from 'modals/RateSpecialistModal';

const CreateOrderPage = () => {
  const { t } = useTranslation();
  const { state: specialistByLocationState } = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { uid, role, referralCode, isLoggedIn } = useAppSelector(
    (state) => state.user,
  );
  const {
    isCalendarOpen,
    dateStamp,
    chosenServices,
    isTimeSelected,
    specialist,
    timeSlotId,
  } = useAppSelector((state) => state.createOrderPage);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const referralCodeByParam = searchParams.get('ref');
  const userReferralCode = referralCode ? referralCode : referralCodeByParam;

  const { data: fetchedSpecialist } = useApiRequest<SpecialistProfileDto>({
    conditionToFetch: !!userReferralCode,
    endpoint: `${BASE_URL}specialist/info/${userReferralCode}`,
    destructuringDataType: 'profileDto',
  });

  useEffect(() => {
    if (!specialistByLocationState && !userReferralCode) {
      navigate('/');
    }
  }, [specialistByLocationState, userReferralCode, navigate]);

  useEffect(() => {
    if (fetchedSpecialist) {
      dispatch(setSpecialistDto(fetchedSpecialist));
      return;
    }
    if (specialistByLocationState?.stringId) {
      dispatch(setSpecialistDto(specialistByLocationState));
    }
  }, [specialistByLocationState, fetchedSpecialist, dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (role === 'specialist' && uid === specialist?.stringId) {
      navigate('/specialist/create-order');
      return;
    }

    const logoutSpecialist = async () => {
      if (role === 'specialist' && uid !== specialist?.stringId) {
        await fetchRequestBoolean(`login/logoutSpecialist/${uid}`, 'POST');
        removeTypedStorageItem('gotouAccessToken');
        removeTypedStorageItem('gotouRefreshToken');
        dispatch(logOutUser());
      }
    };

    logoutSpecialist();
  }, [dispatch, navigate, role, specialist, uid]);

  const handleCreateOrder = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!dateStamp) {
      toastWithDismiss(t('choose_date'), 'error');
      return;
    }

    if (!isLoggedIn) {
      dispatch(
        setOrderDto({
          clientId: uid, // maybe empty for not logged user
          specialistId: specialist.stringId,
          price: chosenServices[0]?.gssDto.price!,
          currency: chosenServices[0]?.gssDto.currency!,
          serviceId: chosenServices[0]?.gssDto.gserviceIdStr!,
          duration: chosenServices[0]?.gssDto.duration!,
          ts: dateStamp,
          role: 'client',
        }),
      );
      navigate('/login-client');
      return;
    }

    const response = await createOrder(
      {
        clientId: uid,
        specialistId: specialist.stringId,
        price: chosenServices[0]?.gssDto.price!,
        currency: chosenServices[0]?.gssDto.currency!,
        serviceId: chosenServices[0]?.gssDto.gserviceIdStr!,
        duration: chosenServices[0]?.gssDto.duration!,
        timeSlotId,
        ts: dateStamp,
        role,
      },
      t,
    );
    if (response?.orderIdStr) {
      toastWithDismiss(t('order_success'), 'success');
      dispatch(resetState());
      navigate(`/orders/${response.orderIdStr}/photo`);
    }
  };

  const toggleCalendarVisibility = () => {
    if (chosenServices.length === 0) {
      toastWithDismiss(t('choose_service'), 'error');
      return;
    }
    dispatch(setIsCalendarOpen(!isCalendarOpen));
  };

  const handleChooseService = (service: Service) => {
    dispatch(clearChosenServices());
    dispatch(addChosenService(service));
  };

  return (
    <Wrapper noHorizontalPadding flexColumnSpaceBetween>
      <SectionHeader
        title={t('create_order.name')}
        wrapperClassName='pl-5'
        showGoBackButton={!!uid}
      />
      <div className='mb-5 flex items-center justify-between gap-4 px-6 smMax:mt-7 xs:px-3'>
        <div className='flex flex-col items-center gap-2'>
          <UserAvatar
            src={specialist?.profilePhoto}
            size={124}
            showLikeButton={false}
          />
          {specialist?.insta && (
            <button
              className='flex items-center gap-2 hover:underline'
              onClick={() =>
                window.open(specialist.insta.replace(/"/g, ''), '_blank')
              }
            >
              <FaInstagram /> <p>Instagram</p>
            </button>
          )}
        </div>
        <SpecialistStats
          rank={specialist?.rating || 0}
          imagesAmount={specialist?.picsCnt}
          portfolioUid={specialist?.stringId}
          reviewsUid={specialist?.stringId}
          reviewsNumber={specialist?.reviewsCnt}
          showLeaveReview={false}
        />
      </div>
      <div className='space-y-5'>
        <div className='flex flex-row items-center justify-between px-6 xs:px-3'>
          <div>
            <h2 className='text-2xl font-medium leading-7'>
              {specialist?.firstName} {specialist?.lastName}
            </h2>
            <p className='text-sm text-secondary'>
              {t('edit_profile.since')} {specialist?.since}
            </p>
          </div>
          <Button
            variant='yellowStroke'
            onClick={() =>
              dispatch(toggleModal({ modalType: 'rateSpecialist' }))
            }
          >
            {t('order.client.leave_review')}
          </Button>
        </div>
        {specialist?.descr && (
          <div className='mx-6 rounded-lg bg-[#F2F2F2] p-3 font-medium xs:mx-3'>
            <p>{specialist?.descr}</p>
          </div>
        )}
      </div>
      <OrderServiceForm
        toggleDatePicker={toggleCalendarVisibility}
        onOrderSubmit={handleCreateOrder}
        handleChooseService={handleChooseService}
      />

      <div className='px-4'>
        <Button
          className='mt-10 w-full py-4'
          variant='yellow'
          type='submit'
          form='create-order'
          disabled={chosenServices.length === 0 || !isTimeSelected}
        >
          {t('menu.create_order')}
        </Button>
      </div>
      {ReactDOM.createPortal(
        <RateSpecialistModal
          specialistName={`${specialist?.firstName} ${specialist?.lastName}`}
        />,
        document.getElementById('root') as Element,
      )}
      {ReactDOM.createPortal(
        <RateSpecialistModal
          specialistName={`${specialist?.firstName} ${specialist?.lastName}`}
        />,
        document.getElementById('root') as Element,
      )}
    </Wrapper>
  );
};

export default CreateOrderPage;
