import React, { ChangeEvent, useEffect, useState } from 'react';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { toggleModal } from 'redux/modalSlice';
import {
  ActionButtons,
  MultilineInput,
  Radio,
  SectionHeader,
  Wrapper,
  Spinner,
  toastWithDismiss,
} from 'ui';
import { useAppSelector } from 'hooks/useAppSelector';
import { useApiRequest } from 'hooks/useApiRequest';
import { BASE_URL, fetchWithTokenRefresh } from 'api/api';
import { CancelReason } from 'utils/types';
import { logOut } from 'api/login';

const DeleteAccountPage = () => {
  const [reasons, setReasons] = useState<CancelReason[]>([]);
  const [selectedReason, setSelectedReason] = useState('');
  const [otherReason, setOtherReason] = useState('');
  const [acceptedReason, setAcceptedReason] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);

  const { uid, role } = useAppSelector((state) => state.user);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { data, loading } = useApiRequest<CancelReason[]>({
    endpoint: `${BASE_URL}${role}s/deleteReasons`,
    requestMethod: 'GET',
    destructuringDataType: 'deleteReasons',
  });

  const handleRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
    setConfirmDelete(true);
    setSelectedReason(event.target.value);
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setOtherReason(event.target.value);
  };

  const handleContinue = async () => {
    try {
      const response = await fetchWithTokenRefresh(`${role}s/delete`, 'POST', {
        deleteReason: selectedReason,
        descr: selectedReason === 'other' ? otherReason : selectedReason,
      });
      if (response.status === 200) {
        await logOut(uid, role as 'client' | 'specialist', dispatch, navigate);
        navigate('/');
        dispatch(toggleModal({ modalType: 'deletedAccount' }));
      }
    } catch (error) {
      toastWithDismiss(t('something_wrong'), 'error');
    }
  };

  const handleCancel = () => {
    if (acceptedReason) {
      setAcceptedReason(false);
      setSelectedReason('');
      setOtherReason('');
      return;
    }
    navigate(-1);
  };

  useEffect(() => {
    if (data) {
      setReasons(data);
    }
  }, [data]);

  return (
    <Wrapper flexColumnSpaceBetween>
      <div>
        <SectionHeader
          title={t('delete_account.title')}
          subtitle={t('delete_account.subtitle')}
        />
        <div className='mb-7 mt-5 space-y-5'>
          <>
            <p className='text-gray-8'>
              {t('delete_account.inquiry_message')}{' '}
              <span className='text-[#4285F4]'>gotou.info@gmail.com.</span>
            </p>
            <p className='text-gray-8'>
              {t('delete_account.feedback_message')}
            </p>
          </>
        </div>

        <div onChange={handleRadioChange} className='flex flex-col gap-4'>
          {loading ? (
            <Spinner />
          ) : (
            reasons.map((reason, i) => (
              <Radio
                name='reason'
                key={reason.reason + i}
                value={reason.reason}
                checked={selectedReason === reason.reason}
                labelClassName='text-md'
                label={reason.displayName}
              />
            ))
          )}
        </div>
        {selectedReason === 'other' && (
          <MultilineInput
            variant='outline'
            label={t('delete_account.other_reason_label')}
            value={otherReason}
            onChange={handleInputChange}
          />
        )}
      </div>
      <div>
        <p className='mx-auto text-center text-gray-8'>
          {t('delete_account.confirmation_message')}
        </p>
        <ActionButtons
          handleCancelClick={handleCancel}
          handleSaveClick={handleContinue}
          saveButtonText={t('delete_account.confirm_delete')}
          isSaveButtonDisabled={
            !acceptedReason ? !selectedReason : !confirmDelete
          }
        />
      </div>
    </Wrapper>
  );
};

export default DeleteAccountPage;
