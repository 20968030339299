import { LocationDto, Schedule, SpecialistProfileDto } from 'utils/types';
import { NavigateFunction } from 'react-router';
import { toggleModal } from 'redux/modalSlice';
import {
  SupportedLanguages,
  setSpecialist,
  setLoginUser,
  setUserLocation,
  setIsNewUser,
  setReferralCode,
  setSpecSubRole,
  setClientSubRole,
} from 'redux/userSlice';
import { AppDispatch } from 'store';
import {
  BASE_URL,
  fetchRequestBoolean,
  fetchRequestData,
  getClientAddress,
} from 'api/api';
import { setSchedule } from 'redux/scheduleSlice';
import { setInstagramLink, setSpecDescription } from 'redux/specialistSlice';
import {
  getTypedStorageItem,
  removeTypedStorageItem,
  setTypedStorageItem,
} from 'utils/storage';
import { logOutUser } from 'redux/actions';

export const sentCode = async (
  phoneNumber: string,
  userRole: 'client' | 'specialist',
  setUid: (uid: string) => void,
  dispatch: AppDispatch,
  ref: string | null,
) => {
  try {
    const response = await fetch(`${BASE_URL}login/${userRole}`, {
      method: 'POST',
      body: JSON.stringify({ phoneNumber, ref }),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data: {
      id: number;
      stringId: string;
    } = await response.json();
    setUid(data.stringId);
  } catch (error) {
    dispatch(toggleModal({ modalType: 'error' }));
  }
};

export const resendCode = async (
  uid: string,
  role: 'client' | 'specialist',
) => {
  try {
    const url =
      role! === 'specialist'
        ? 'login/specialist/resendCode'
        : 'login/resendCode';

    const response = await fetch(`${BASE_URL}${url}`, {
      method: 'POST',
      body: JSON.stringify({ uid }),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (!response.ok) {
      throw new Error('Request failed');
    }
  } catch (error) {}
};

export const verifyCode = async (
  uid: string,
  phoneNumber: string,
  role: 'client' | 'specialist',
  codeSMS: string,
  dispatch: AppDispatch,
  navigate: NavigateFunction,
  setIsValid: (boolean: boolean) => void,
  refferalCode: string | null,
) => {
  try {
    const response = await fetchRequestData<{
      accessToken: string;
      refreshToken: string;
      tokenType: string;
    }>(`login/${role}/verifyCode/${uid}/${codeSMS}`, 'GET');

    if (!response) {
      throw new Error('Request failed');
    }
    setTypedStorageItem('gotouAccessToken', response.accessToken);
    setTypedStorageItem('gotouRefreshToken', response.refreshToken);
    setTypedStorageItem('gotouTokenType', response.tokenType);

    try {
      const userLocale = getTypedStorageItem('selectedLanguage') || 'en';

      const { locale } = await fetchRequestData<{
        locale: (typeof SupportedLanguages)[number];
      }>(`app-config/getLocale`);

      if (locale !== userLocale) {
        await fetchRequestBoolean(
          `app-config/changeLocale?selectedLocale=${userLocale}`,
          'POST',
        );
      }
    } catch (error) {}

    if (role === 'specialist') {
      await handleSpecialist(uid, phoneNumber, role, dispatch, navigate);
    } else {
      await handleClient(
        uid,
        phoneNumber,
        role,
        dispatch,
        navigate,
        refferalCode,
      );
    }
    logSmile('happy');
  } catch (error) {
    setIsValid(false);
  }
};

export const verifyCodeForOrder = async (info: {
  uid: string;
  phoneNumber: string;
  role: 'client' | 'specialist';
  codeSMS: string;
  dispatch: AppDispatch;
  setIsValid: (boolean: boolean) => void;
  firstName: string;
  lastName: string;
}): Promise<boolean> => {
  try {
    const response = await fetchRequestData<{
      accessToken: string;
      refreshToken: string;
      tokenType: string;
    }>(`login/${info.role}/verifyCode/${info.uid}/${info.codeSMS}`, 'GET');

    if (!response) {
      throw new Error('Request failed');
    }
    setTypedStorageItem('gotouAccessToken', response.accessToken);
    setTypedStorageItem('gotouRefreshToken', response.refreshToken);
    setTypedStorageItem('gotouTokenType', response.tokenType);

    try {
      const userLocale = getTypedStorageItem('selectedLanguage') || 'en';

      const { locale } = await fetchRequestData<{
        locale: (typeof SupportedLanguages)[number];
      }>(`app-config/getLocale`);

      if (locale !== userLocale) {
        await fetchRequestBoolean(
          `app-config/changeLocale?selectedLocale=${userLocale}`,
          'POST',
        );
      }
    } catch (error) {}
    const { fullName, latitude, longitude, country, city } =
      await getClientAddress();

    const { name, photoUrl, surname } = await fetchRequestData<{
      address: string;
      name: string;
      photoUrl: string;
      surname: string;
    }>(`clients/info`, 'GET');

    info.dispatch(setIsNewUser(false));
    info.dispatch(
      setLoginUser({
        uid: info.uid,
        role: info.role,
        phoneNumber: info.phoneNumber,
        firstName: name || info.firstName,
        lastName: surname || info.lastName,
        isLogged: true,
        profilePhoto: photoUrl,
        address: fullName,
        country,
        city,
        coordinates: { lat: latitude, lng: longitude },
      }),
    );

    return true;
  } catch (error) {
    info.setIsValid(false);
    return false;
  }
};

const handleSpecialist = async (
  uid: string,
  phoneNumber: string,
  role: 'specialist',
  dispatch: AppDispatch,
  navigate: NavigateFunction,
) => {
  const { profileDto, schedule, specLocation } = await getSpecialistInfo(uid);

  try {
    const { referralCode } = await fetchRequestData<{
      referralCode: string;
    }>(`specialist/generateReferral`, 'POST', {
      specialistId: uid,
    });

    dispatch(setReferralCode(referralCode));
  } catch (error) {}

  dispatch(
    setSpecialist({
      uid,
      phoneNumber,
      role,
      ...profileDto,
    }),
  );
  if (profileDto.insta) {
    dispatch(setInstagramLink(profileDto.insta.replace(/"/g, '')));
  }
  if (profileDto.descr) {
    dispatch(setSpecDescription(profileDto.descr.replace(/"/g, '')));
  }

  if (!specLocation.city) {
    dispatch(setSpecSubRole('acceptingTerms'));
    dispatch(toggleModal({ modalType: 'terms' }));
    dispatch(setIsNewUser(true));

    return;
  }

  let linkToNavigate = '';

  if (profileDto) {
    dispatch(setSpecSubRole('editingProfile'));
    linkToNavigate = 'specialist/profile/edit';
  }

  if (specLocation) {
    dispatch(
      setUserLocation({
        address: profileDto.address,
        coordinates: {
          lat: specLocation.latitude!,
          lng: specLocation.longitude!,
        },
        city: specLocation.city,
        country: specLocation.country,
      }),
    );
    dispatch(setSpecSubRole('editingSchedule'));

    linkToNavigate = 'specialist/schedule';
  }
  if (Array.isArray(schedule) && schedule.length > 0) {
    dispatch(setSchedule(schedule));
    dispatch(setSpecSubRole('full'));
    linkToNavigate = 'specialist/profile';
  }

  dispatch(setIsNewUser(linkToNavigate !== 'specialist/profile'));

  navigate(linkToNavigate);
};

const handleClient = async (
  uid: string,
  phoneNumber: string,
  role: 'client',
  dispatch: AppDispatch,
  navigate: NavigateFunction,
  refferalCode: string | null,
) => {
  const { fullName, latitude, longitude, country, city } =
    await getClientAddress();

  const { name, photoUrl, surname } = await fetchRequestData<{
    address: string;
    name: string;
    photoUrl: string;
    surname: string;
  }>(`clients/info`, 'GET');

  const user = {
    uid,
    phoneNumber,
    role,
    firstName: name,
    lastName: surname,
    profilePhoto: photoUrl,
  };

  if (fullName && longitude && country && city && name) {
    dispatch(
      setLoginUser({
        ...user,
        address: fullName,
        country,
        city,
        coordinates: { lat: latitude, lng: longitude },
      }),
    );
    dispatch(setClientSubRole('full'));
    if (refferalCode) {
      navigate(`/create-order?ref=${refferalCode}`);
      return;
    }

    navigate('/category');
  } else {
    dispatch(setLoginUser(user));
    dispatch(setClientSubRole('editingProfile'));
    navigate('/profile/edit');
  }
};

export const logOut = async (
  uid: string,
  role: 'client' | 'specialist',
  dispatch: AppDispatch,
  navigate: NavigateFunction,
) => {
  const user = role === 'client' ? 'Client' : 'Specialist';

  navigate(role === 'client' ? '/' : '/specialist');
  await fetchRequestBoolean(`login/logout${user}/${uid}`, 'POST');
  logSmile('sad');

  removeTypedStorageItem('gotouAccessToken');
  removeTypedStorageItem('gotouRefreshToken');
  dispatch(logOutUser());
};

const getSpecialistInfo = async (uid: string) => {
  const { profileDto } = await fetchRequestData<{
    profileDto: SpecialistProfileDto;
  }>(`specialists/${uid}`, 'GET');
  const specLocation = await fetchRequestData<LocationDto>(
    `specialists/${uid}/location`,
    'GET',
  );
  const { dtos } = await fetchRequestData<{ dtos: Schedule[] }>(
    `specialists/${uid}/schedule`,
    'GET',
  );
  return { profileDto, specLocation, schedule: dtos };
};

const logSmile = (type: 'sad' | 'happy') => {
  if (type === 'happy') {
    console.log(
      '⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜\n' +
        '⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜\n' +
        '⬜⬜⬜⬛⬛⬛⬜⬜⬜⬛⬛⬛⬜⬜⬜\n' +
        '⬜⬜⬛⬛⬛⬛⬛⬜⬛⬛⬛⬛⬛⬜⬜\n' +
        '⬜⬜⬛⬛⬜⬛⬛⬛⬛⬛⬜⬛⬛⬜⬜\n' +
        '⬜⬜⬛⬛⬛⬛⬛⬜⬛⬛⬛⬛⬛⬜⬜\n' +
        '⬜⬜⬜⬛⬛⬛⬜⬜⬜⬛⬛⬛⬜⬜⬜\n' +
        '⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜\n' +
        '⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜\n' +
        '⬜⬜🟨🟨⬜⬜⬜⬜⬜⬜🟨🟨⬜⬜⬜\n' +
        '⬜⬜🟨🟨🟨⬜⬜⬜⬜🟨🟨🟨⬜⬜⬜\n' +
        '⬜⬜⬜🟨🟨🟨⬜⬜🟨🟨🟨⬜⬜⬜⬜\n' +
        '⬜⬜⬜🟨🟨🟨🟨🟨🟨🟨🟨⬜⬜⬜⬜\n' +
        '⬜⬜⬜⬜⬜🟨🟨🟨🟨⬜⬜⬜⬜⬜⬜\n' +
        '⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜\n' +
        '⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜',
    );
  } else {
    console.log(
      '⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜\n' +
        '⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜\n' +
        '⬜⬜⬜⬛⬛⬛⬜⬜⬜⬛⬛⬛⬜⬜⬜\n' +
        '⬜⬜⬛⬛⬛⬛⬛⬜⬛⬛⬛⬛⬛⬜⬜\n' +
        '⬜⬜⬛⬛⬜⬛⬛⬛⬛⬛⬜⬛⬛⬜⬜\n' +
        '⬜⬜⬛⬛⬛⬛⬛⬜⬛⬛⬛⬛⬛⬜⬜\n' +
        '⬜⬜⬜⬛⬛⬛⬜⬜⬜⬛⬛⬛⬜⬜⬜\n' +
        '⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜\n' +
        '⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜\n' +
        '⬜⬜⬜⬜⬜🟨🟨🟨🟨⬜⬜⬜⬜⬜⬜\n' +
        '⬜⬜⬜🟨🟨🟨🟨🟨🟨🟨🟨⬜⬜⬜⬜\n' +
        '⬜⬜🟨🟨🟨⬜⬜⬜⬜🟨🟨🟨⬜⬜⬜\n' +
        '⬜⬜🟨🟨⬜⬜⬜⬜⬜⬜🟨🟨⬜⬜⬜\n' +
        '⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜\n' +
        '⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜\n',
    );
  }
};
