import React from 'react';
import { useNavigate } from 'react-router';
import ReactGA from 'react-ga';

type NavigationItemProps = {
  title: string;
  href: string;
  isVerticalLayout?: boolean;
  onClick?: () => void;
};

const NavigationItem = ({
  title,
  href,
  isVerticalLayout,
  onClick,
}: NavigationItemProps) => {
  const navigate = useNavigate();

  const handleNavigation = (link: string) => {
    ReactGA.event({
      category: 'Navigation',
      action: 'Clicked Navigation Link',
      label: title,
    });

    if (onClick) onClick();

    const element = document.getElementById(link);
    if (element) {
      const offset = 100;
      const elementPosition =
        element.getBoundingClientRect().top + window.pageYOffset;
      const offsetPosition = elementPosition - offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    } else {
      navigate(link);
    }
  };

  return (
    <li
      onClick={() => handleNavigation(href)}
      className={`${
        isVerticalLayout
          ? 'w-full border-b border-[#EDCAB1] hover:border-accent hover:text-accent'
          : ''
      }`}
    >
      <button className='font-sans text-md2 leading-8 hover:underline md:text-[1.31rem]'>
        {title}
      </button>
    </li>
  );
};

export default NavigationItem;
