import React, { useState } from 'react';

import useOnclickOutside from 'react-cool-onclickoutside';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

import { Button, LanguageDropDown } from 'ui';
import { setUserRole } from 'redux/userSlice';
import { useAppDispatch } from 'hooks/useAppDispatch';
import ProfileMenuButton from 'components/header/ProfileMenuButton';
import { useAppSelector } from 'hooks/useAppSelector';
import Navigation from 'components/Main/Navigation';
import LogoBusiness from 'images/main-specialist/Logo-business.svg';
import LoginButton from 'components/Main/LoginButton';
import BurgerMenuButton from 'components/Main/BurgerMenuButton';

const navigationLinks = (t: any) => [
  {
    title: t('businessPage.navigation.advantages'),
    href: 'advantages',
  },
  {
    title: t('businessPage.navigation.reviews'),
    href: 'reviews',
  },
  {
    title: t('businessPage.buttons.clients'),
    href: '/',
  },
];

const MainSpecialistHeader = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isLoggedIn } = useAppSelector((state) => state.user);

  const [isOpen, setIsOpen] = useState(false);
  const ref = useOnclickOutside(() => {
    setIsOpen(false);
  });

  const handleLogin = () => {
    dispatch(setUserRole('specialist'));
    navigate('/login');
  };

  return (
    <header className='main-page-wrapper sticky left-0 top-0 z-50 max-h-[15vh] w-full bg-[#F6F5F4] px-5 md:px-[50px]'>
      <nav className='mx-auto flex items-center justify-between py-5'>
        <a href='/specialist' className='flex items-center'>
          <img
            src={LogoBusiness}
            height={40}
            width={86}
            alt='GoTou Logo'
            draggable={false}
          />
        </a>
        <div className='flex items-center gap-5'>
          <div className='hidden lg:block'>
            <Navigation links={navigationLinks(t)} />
          </div>
          <div className='md:ml-14 xs:hidden'>
            <LanguageDropDown />
          </div>
          {isLoggedIn ? <ProfileMenuButton /> : <LoginButton />}
          <div ref={ref} className='lg:hidden'>
            <BurgerMenuButton setIsOpen={setIsOpen} isOpen={isOpen} />
            <div
              className={`transfrom absolute left-0 right-0 top-0 flex flex-col gap-12 rounded-b-[20px] bg-white px-[10%] pb-12 pt-20 transition-all duration-200 ease-in-out xs:px-[5%] ${
                isOpen
                  ? 'translate-y-0 opacity-100'
                  : 'pointer-events-none -translate-y-10 opacity-0'
              } -z-20`}
            >
              <Navigation links={navigationLinks(t)} isVerticalLayout />
              {!isLoggedIn && (
                <Button
                  onClick={handleLogin}
                  variant='outline-botox'
                  className='h-12 w-fit !py-2 !text-md2'
                >
                  {t('button_2')}
                </Button>
              )}
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default MainSpecialistHeader;
