import React from 'react';
import CalendarIcon from 'images/emoji/Hours.svg';
import { useTranslation } from 'react-i18next';

type DurationSectionProps = {
  hours: number;
  setHours: (value: number) => void;
  minutes: number;
  setMinutes: (value: number) => void;
};

const DurationSection = ({
  hours,
  minutes,
  setHours,
  setMinutes,
}: DurationSectionProps) => {
  const { t } = useTranslation();

  return (
    <div className='flex items-start justify-between'>
      <div className='flex gap-2'>
        <img
          src={CalendarIcon}
          alt='icon'
          width={24}
          height={24}
          className='xs:hidden'
        />
        <p>{t('add_service_page.duration')}</p>
      </div>
      <div className='flex flex-col gap-4'>
        <div className='flex gap-5'>
          <div
            className='relative
my-2 rounded-lg border border-gray-3 px-4 py-2 transition-all hover:border-accent'
          >
            <label
              htmlFor='hours'
              className='absolute -top-2 left-1 bg-white text-xs text-gray-2'
            >
              {t('add_service_page.hours')}
            </label>
            <div>
              <select
                name='hours'
                id='hours'
                className='bg-white p-2 outline-none'
                value={hours}
                onChange={(e) => setHours(Number(e.target.value))}
                required
              >
                {Array.from({ length: 12 }, (_, index) => (
                  <option key={index} value={index}>
                    {index}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div
            className='relative
my-2 rounded-lg border border-gray-3 px-4 py-2 transition-all hover:border-accent'
          >
            <label
              htmlFor='minutes'
              className='absolute -top-2 left-1 bg-white text-xs text-gray-2'
            >
              {t('add_service_page.minutes')}
            </label>
            <select
              name='minutes'
              id='minutes'
              className='bg-white p-2 outline-none'
              value={minutes}
              onChange={(e) => setMinutes(Number(e.target.value))}
              required
            >
              {Array.from({ length: 13 }, (_, index) => {
                const value = index * 5;
                return (
                  <option key={index} value={value}>
                    {value.toString().padStart(2, '0')}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DurationSection;
