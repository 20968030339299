import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import { setOrderTime } from 'redux/orderTimeSlice';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import {
  Button,
  TimeSlotsList,
  HeaderDatePicker,
  SectionHeader,
  Wrapper,
} from 'ui';
import { daysInMonth, THIRTY_DAYS_IN_MS } from 'utils/time';
import { resetSearchOrder } from 'redux/searchOrderSlice';
import { useTranslation } from 'react-i18next';
import CalendarIcon from 'images/emoji/(Calendar).svg';
import MoneyIcon from 'images/emoji/(Money).svg';

const SchedulePage = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { dateStamp, formattedDate, timeSlots, timeSlotId } = useAppSelector(
    (state) => state.orderTime,
  );

  const handleChangeDate = (date: Date) => {
    dispatch(
      setOrderTime({
        dateStamp: date.getTime(),
        timeStamp: null,
        timeSlotId: null,
      }),
    );
  };

  const handleSelectTimeSlot = (time: Date, timeSlotId: number) => {
    dispatch(
      setOrderTime({
        dateStamp: time.getTime(),
        timeStamp: time.getTime(),
        timeSlotId,
      }),
    );
  };

  const timeSlotsDate = () => {
    // Format the dateStamp as a date without time (year, month, day)
    const targetDate = dateStamp
      ? new Date(dateStamp).setHours(0, 0, 0, 0)
      : null;

    const now = Date.now();

    // Filter and map the timeslots based on the date comparison
    return timeSlots.filter((timeSlot) => {
      const timeSlotDate = new Date(timeSlot.startTime).setHours(0, 0, 0, 0);
      const timeSlotTime = new Date(timeSlot.startTime).getTime();
      return timeSlotDate === targetDate && timeSlotTime > now; // Filter out the past time slots and the ones that are not on the selected date
    });
  };

  const disabledDates = useMemo(() => {
    const allDates = daysInMonth(new Date(dateStamp));

    const availableDates = timeSlots.map((timeSlot) => {
      const date = new Date(timeSlot.startTime);
      return new Date(date.getFullYear(), date.getMonth(), date.getDate());
    });

    const uniqueAvailableDates = Array.from(new Set(availableDates));

    const disabledDates = allDates.filter(
      (date) =>
        !uniqueAvailableDates.some(
          (availableDate) => availableDate.getTime() === date.getTime(),
        ),
    );

    return disabledDates;
  }, [dateStamp, timeSlots]);

  const isDisabled = !timeSlotsDate().length;

  return (
    <Wrapper flexColumnSpaceBetween>
      <SectionHeader
        title={t('order.change_date.schedule_a_visit')}
        subtitle={
          isDisabled
            ? 'No available dates for the selected service'
            : !formattedDate
              ? t('order.change_date.enter_time')
              : formattedDate
        }
      />

      <DatePicker
        inline
        selected={isDisabled ? null : new Date(dateStamp)}
        onChange={handleChangeDate}
        dateFormat='MMMM d, yyyy h:mm aa'
        minDate={new Date()}
        maxDate={new Date(Date.now() + THIRTY_DAYS_IN_MS)}
        renderCustomHeader={HeaderDatePicker}
        excludeDates={disabledDates}
      />
      <div className='space-y-3'>
        <TimeSlotsList
          timeSlots={timeSlotsDate()}
          handleSelectTime={handleSelectTimeSlot}
        />
      </div>
      <div className='my-10 flex flex-col gap-2'>
        {t('choose_time')
          .split('.')
          .map((sentence, i) => (
            <div key={sentence + i} className='flex gap-2'>
              <img src={i === 0 ? CalendarIcon : MoneyIcon} alt='emoji' />
              <p className='text-left text-lg'>{sentence}</p>
            </div>
          ))}
      </div>
      <Button
        variant='yellow'
        className='w-full py-4'
        disabled={!timeSlotId}
        onClick={() => {
          dispatch(resetSearchOrder());
          navigate('/search');
        }}
      >
        {t('order.change_date.find_service')}
      </Button>
    </Wrapper>
  );
};

export default SchedulePage;
