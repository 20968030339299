import React, { useEffect, useState } from 'react';
import imageCompression from 'browser-image-compression';
import { FaCircleCheck, FaCirclePlus } from 'react-icons/fa6';
import {
  ActionButtons,
  ImageGallery,
  SectionHeader,
  Sheet,
  Wrapper,
  toastWithDismiss,
} from 'ui';
import { MdDelete, MdOpenInFull } from 'react-icons/md';
import { useApiRequest } from 'hooks/useApiRequest';
import { useAppSelector } from 'hooks/useAppSelector';
import { BASE_URL, addMediaToGallery, deletePhotoFromGallery } from 'api/api';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { toggleModal } from 'redux/modalSlice';
import { useParams } from 'react-router';
import { setImageAmount } from 'redux/userSlice';
import AddImageIcon from 'images/icons/gallery-add-image.png';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { isVideo } from 'utils/validations';

const COMPRESSION_OPTIONS = {
  maxSizeMB: 0.6,
  maxWidthOrHeight: 1920,
  useWebWorker: true,
};

const PortfolioPage = () => {
  const { t } = useTranslation();

  const { uid } = useAppSelector((state) => state.user);
  const { id } = useParams();
  const dispatch = useAppDispatch();

  const [images, setImages] = useState<string[]>([]);
  const [showGallery, setShowGallery] = useState(false);
  const [galleryStartIndex, setGalleryStartIndex] = useState(0);
  const [isOpenSheet, setIsOpenSheet] = useState(false);
  const [selectedImageIndices, setSelectedImageIndices] = useState<number[]>(
    [],
  );
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  const { data: fetchedImages } = useApiRequest({
    endpoint: `${BASE_URL}specialists/gallery/${id}`,
    destructuringDataType: 'urls',
    requestMethod: 'POST',
  });
  const isPortfolioOwner = id === uid;

  useEffect(() => {
    if (fetchedImages) {
      setImages(fetchedImages);
      dispatch(setImageAmount(fetchedImages.length));
    }
  }, [fetchedImages, dispatch]);

  const handleImageClick = (index: number) => {
    if (!isPortfolioOwner) {
      setGalleryStartIndex(index);
      setShowGallery(true);
      return;
    }
    if (selectedImageIndices.includes(index)) {
      if (selectedImageIndices.length === 1) setIsOpenSheet(false);
      setSelectedImageIndices(selectedImageIndices.filter((i) => i !== index));
    } else {
      setSelectedImageIndices([...selectedImageIndices, index]);
      setIsOpenSheet(true);
    }
  };

  const handleCloseSheet = () => {
    setShowConfirmDelete(false);
    setIsOpenSheet(false);
    setSelectedImageIndices([]);
  };

  const handleAddMedia = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!isPortfolioOwner || !e.target.files) return;
    if (e.target.files.length > 5) {
      toastWithDismiss('You can only upload up to 5 files at a time', 'error');
      return;
    }
    const files = Array.from(e.target.files);

    const newMediaURLs: any[] = []; // Store new media URLs here
    const compressedFiles = [];
    const loadingToast = toast.loading('Uploading media...');

    for (const file of files) {
      let compressedFile = await imageCompression(file, COMPRESSION_OPTIONS);
      newMediaURLs.push(URL.createObjectURL(compressedFile)); // Add compressed image URL
      compressedFiles.push(compressedFile);
    }

    const response = await addMediaToGallery(uid, compressedFiles);
    toast.dismiss(loadingToast);

    if (response && response.ok) {
      toast.success(t('specialist.portfolio_img_added'));
      setImages((prevImages) => [...prevImages, ...newMediaURLs]); // Update state once with all new media URLs
      dispatch(setImageAmount(images.length + newMediaURLs.length));
    } else {
      dispatch(toggleModal({ modalType: 'error' }));
    }
  };

  const handleRemoveMedia = async () => {
    setShowConfirmDelete(false);

    const urls = selectedImageIndices.map((i) => images[i]);
    handleCloseSheet();
    setSelectedImageIndices([]);
    const response = await deletePhotoFromGallery(urls);
    if (response) {
      setImages((prevImages) =>
        prevImages.filter((image) => !urls.includes(image)),
      );
      dispatch(setImageAmount(images.length - urls.length));
      toast.success(t('specialist.portfolio_img_removed'));
    } else dispatch(toggleModal({ modalType: 'error' }));
  };

  const handleOpenImage = () => {
    setGalleryStartIndex(selectedImageIndices[0]);
    setShowGallery(true);
    handleCloseSheet();
  };

  return (
    <Wrapper>
      <SectionHeader title={t('order.specialist_found.portfolio')} />
      <div className='mt-3 grid grid-cols-2 justify-items-center gap-5 sm:max-h-[90vh] sm:overflow-y-auto sm:overflow-x-hidden smMax:auto-rows-fr smMax:grid-rows-2'>
        {images.map((url: string, i: number) => (
          <div
            className='relative max-h-[132px] max-w-[188px] cursor-pointer'
            key={`${url}-${i}`}
            onClick={() => handleImageClick(i)}
          >
            {isVideo(url) ? (
              <video
                src={url}
                className='aspect-[188/132] rounded-md object-cover'
                width='186'
                height='132'
              />
            ) : (
              <img
                src={url}
                alt='specialist portfolio'
                className='aspect-[188/132] rounded-md object-cover'
                width='186'
                height='132'
                loading='lazy'
              />
            )}
            {selectedImageIndices.includes(i) && (
              <FaCircleCheck
                className='absolute right-0 top-0 m-2 rounded-[50%] bg-white text-accent'
                size={25}
              />
            )}
          </div>
        ))}
        {isPortfolioOwner && (
          <label
            htmlFor='dropzone-file'
            className='relative aspect-[188/132] max-h-[132px] w-full max-w-[188px] cursor-pointer rounded-md bg-gray-200'
            style={{
              backgroundImage: `url(${AddImageIcon})`,
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
            }}
          >
            <FaCirclePlus
              className='ml-auto mr-2 mt-2 rounded-full bg-white text-accent sm:bottom-20 sm:right-24'
              size={30}
            />
            <input
              id='dropzone-file'
              accept='image/jpeg, image/png'
              type='file'
              className='hidden'
              multiple
              onClick={() =>
                toast('Max 5 images at the same time', {
                  duration: 1000,
                })
              }
              onChange={handleAddMedia}
            />
          </label>
        )}
      </div>
      {isOpenSheet && (
        <Sheet className='items-center' handleClose={handleCloseSheet}>
          <p className='font-medium'>
            {t(showConfirmDelete ? 'delete_images' : 'portfolio.choose_action')}
          </p>
          {showConfirmDelete ? (
            <ActionButtons
              handleCancelClick={handleCloseSheet}
              saveButtonText={t('ok')}
              handleSaveClick={handleRemoveMedia}
            />
          ) : (
            <div className='flex gap-5'>
              <div
                className='flex  flex-col items-center gap-2'
                onClick={() => setShowConfirmDelete(true)}
              >
                <MdDelete
                  size={50}
                  className='cursor-pointer rounded-full bg-accent py-2 transition-all hover:scale-105'
                />
                <p className='cursor-default'>{t('portfolio.remove')}</p>
              </div>
              {selectedImageIndices.length === 1 && (
                <div
                  className='flex  flex-col items-center gap-2'
                  onClick={handleOpenImage}
                >
                  <MdOpenInFull
                    size={50}
                    className='cursor-pointer rounded-full bg-accent py-2 transition-all hover:scale-105'
                  />
                  <p className='cursor-default'>{t('portfolio.open_image')}</p>
                </div>
              )}
            </div>
          )}
        </Sheet>
      )}
      {showGallery && (
        <ImageGallery
          galleryStartIndex={galleryStartIndex}
          galleryImages={images}
          setShowGallery={setShowGallery}
        />
      )}
    </Wrapper>
  );
};

export default PortfolioPage;
