import React, { useState } from 'react';
import Logo from 'images/icons/logo.svg';
import Person from 'images/icons/Person.svg';
import { motion } from 'framer-motion';

type CircleLogoProps = {
  avatar?: string;
  loading?: boolean;
};

const pulseAnimation = {
  animate: {
    scale: [1, 1.05, 1],
    rotate: [0, 360, 0],
    transition: {
      scale: {
        duration: 1,
        type: 'tween',
        repeat: Infinity,
      },
      rotate: {
        duration: 3,
        type: 'tween',
        repeat: Infinity,
        yoyo: Infinity,
        bounce: 0.3,
      },
    },
  },
};

const stopAnimation = {
  animate: {
    scale: 1,
    transition: {
      duration: 0.6,
      type: 'spring',
    },
  },
};

const CircleLogo = ({ avatar, loading }: CircleLogoProps) => {
  const [imageError, setImageError] = useState(false);

  return (
    <motion.div
      className={`flex h-[282px] w-[282px] items-center justify-center rounded-full bg-[#FFDD64]`}
      {...(loading ? pulseAnimation : stopAnimation)}
    >
      <div className='flex h-[230px] w-[230px] items-center justify-center rounded-full bg-[#FFE27A]'>
        <div
          className='flex h-[153px] w-[153px] items-center justify-center rounded-full bg-[#FFE68E]'
          style={{
            backgroundImage: `${loading || imageError ? '' : `url(${avatar})`}`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }}
        >
          {(loading || !avatar) && (
            <div className='flex h-[100px] w-[100px] items-center justify-center rounded-full bg-[#FFFAE8]'>
              <div className='flex h-[60px] w-[60px] items-center justify-center rounded-full bg-[#FFFFFF]'>
                <img src={Logo} alt='Logo' />
              </div>
            </div>
          )}
          {!loading && avatar && !imageError && (
            <img
              src={avatar}
              alt='Avatar'
              className='hidden'
              onError={() => setImageError(true)}
            />
          )}
          {imageError && (
            <div className='flex h-[100px] w-[100px] items-center justify-center rounded-full bg-[#FFFAE8]'>
              <div className='flex h-[60px] w-[60px] items-center justify-center rounded-full bg-[#FFFFFF]'>
                <img src={Person} alt='Person' />
              </div>
            </div>
          )}
        </div>
      </div>
    </motion.div>
  );
};

export default CircleLogo;
