import React from 'react';
import Header from 'components/header/Header';
import { Outlet } from 'react-router-dom';
import 'styles/legal.css';

const LegalLayout = () => {
  return (
    <div>
      <Header />
      <main id='legal' className='mx-auto max-w-[740px] px-6'>
        <Outlet />
      </main>
    </div>
  );
};

export default LegalLayout;
