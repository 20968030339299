import React, { useEffect, useState } from 'react';
import SectionLabel from './SectionLabel';
import { AnimatePresence, Variants, motion } from 'framer-motion';
import { Service } from 'utils/types';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { setShowSheetOfServices } from 'redux/createOrderPageSlice';
import StarManIcon from 'images/emoji/StarMan.png';
import { useAppSelector } from 'hooks/useAppSelector';
import { ServiceSelectionButton } from 'ui';
import { useApiRequest } from 'hooks/useApiRequest';
import { BASE_URL } from 'api/api';

type ServiceSectionProps = {
  handleChooseService: (service: Service) => void;
};

const variants: Variants = {
  open: {
    opacity: 1,
    height: 'auto',
    type: 'tween',
  },
  closed: { opacity: 0, height: 0, type: 'tween' },
};

const ServiceSection = ({ handleChooseService }: ServiceSectionProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    showSheetOfServices,
    isOpenSelectCustomer,
    specialist,
    chosenServices,
  } = useAppSelector((state) => state.createOrderPage);
  const { role, uid } = useAppSelector((state) => state.user);

  const [services, setServices] = useState<Service[]>([]);

  const specialistId = role === 'specialist' ? uid : specialist?.stringId;

  const { data: fetchedServices, loading } = useApiRequest<Service[]>({
    conditionToFetch: !!specialistId && showSheetOfServices && !services.length,
    endpoint: `${BASE_URL}specialists/${specialistId}/services`,
    destructuringDataType: 'services',
    deps: [showSheetOfServices],
  });

  useEffect(() => {
    if (fetchedServices) {
      setServices(fetchedServices);
    }
  }, [fetchedServices]);

  return (
    <>
      <SectionLabel
        title={t('create_order.specialist_services')}
        description={chosenServices.map((service) => service.name).join(', ')}
        icon={StarManIcon}
        enable={showSheetOfServices}
        onClick={() => dispatch(setShowSheetOfServices(!showSheetOfServices))}
      />
      <AnimatePresence>
        {showSheetOfServices && (
          <motion.li
            variants={variants}
            initial='closed'
            animate={!isOpenSelectCustomer ? 'open' : 'closed'}
            exit='closed'
          >
            <ul className='my-2 flex max-h-[35vh] flex-col gap-3 overflow-y-auto px-3 pr-2'>
              {services?.map((service, i) => {
                const isChosen = chosenServices.some(
                  (chosenService) =>
                    chosenService.stringId === service.stringId &&
                    chosenService.gssDto.createdAt === service.gssDto.createdAt,
                );
                return (
                  <ServiceSelectionButton
                    service={service}
                    isChosen={isChosen}
                    onClick={() => handleChooseService(service)}
                    key={service.id + i}
                  />
                );
              })}
              {services?.length === 0 && !loading && (
                <p className='text-center text-secondary'>
                  {t('order.no_services_available')}
                </p>
              )}
            </ul>
          </motion.li>
        )}
      </AnimatePresence>
    </>
  );
};

export default ServiceSection;
