import React from 'react';
import { Service } from 'utils/types';

import { AnimatePresence } from 'framer-motion';
import PriceSection from './PriceSection';
import DateSection from './DateSection';
import ServiceSection from './ServiceSection';
import ClientSection from './ClientSection';
import { useAppSelector } from 'hooks/useAppSelector';

type OrderServiceFormProps = {
  onOrderSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  toggleDatePicker: () => void;
  handleChooseService: (service: Service) => void;
};

const OrderServiceForm = ({
  onOrderSubmit,
  toggleDatePicker,
  handleChooseService,
}: OrderServiceFormProps) => {
  const { isOpenSelectCustomer } = useAppSelector(
    (state) => state.createOrderPage,
  );
  const { role } = useAppSelector((state) => state.user);

  return (
    <form onSubmit={onOrderSubmit} id='create-order'>
      <ul className='flex flex-col'>
        {role === 'specialist' && (
          <AnimatePresence>
            <ClientSection />
          </AnimatePresence>
        )}
        {!isOpenSelectCustomer && (
          <>
            <ServiceSection handleChooseService={handleChooseService} />
            <DateSection toggleDatePicker={toggleDatePicker} />
            <PriceSection />
          </>
        )}
      </ul>
    </form>
  );
};

export default OrderServiceForm;
