import React, { useState } from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';
import { useAppSelector } from 'hooks/useAppSelector';
import ProfileAvatarLink from './ProfileAvatarLink';
import { useTranslation } from 'react-i18next';
import { IoCloseOutline } from 'react-icons/io5';
import { Dropdown, UserAvatar } from 'ui';
import { logOut } from 'api/login';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useNavigate } from 'react-router';

const ProfileMenuButton = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const { role, profilePhoto, uid } = useAppSelector((state) => state.user);

  const ref = useOnclickOutside(() => {
    setIsOpen(false);
  });

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleLogOut = async () => {
    await logOut(uid, role as 'client' | 'specialist', dispatch, navigate);
  };

  const clientLinks = [
    { text: t('profile.create_order'), link: '/category' },
    { text: t('menu.my_orders'), link: '/orders' },
    { text: t('menu.my_favorites'), link: '/favorites' },
    { text: t('menu.edit_profile'), link: '/profile/edit' },
  ];

  const specialistLinks = [
    { text: t('menu.my_services'), link: '/specialist/services' },
    { text: t('menu.orders'), link: '/specialist/orders' },
    { text: t('profile.create_order'), link: '/specialist/create-order' },
    { text: t('menu.calendar'), link: '/specialist/calendar' },
    { text: t('menu.schedule'), link: '/specialist/schedule' },
  ];

  return (
    <div className='relative inline-block h-12' ref={ref}>
      <button onClick={toggleDropdown}>
        <UserAvatar
          size={48}
          src={profilePhoto}
          showLikeButton={false}
          circleAvatar
        />
      </button>
      <Dropdown
        show={isOpen}
        position='left'
        className='z-30 w-64 !gap-0 !py-0 text-left shadow-xl'
      >
        <IoCloseOutline
          onClick={toggleDropdown}
          size={24}
          className='absolute right-3 top-3 cursor-pointer text-3xl hover:scale-110'
        />
        <ul className='py-1 pt-5' onClick={toggleDropdown}>
          {role === 'specialist' &&
            specialistLinks.map((link, index) => (
              <ProfileAvatarLink
                key={index}
                text={link.text}
                link={link.link}
              />
            ))}
          {role === 'client' &&
            clientLinks.map((link, index) => (
              <ProfileAvatarLink
                key={index}
                text={link.text}
                link={link.link}
              />
            ))}
          <ProfileAvatarLink text={t('menu.support')} link='/support' />
          <ProfileAvatarLink text={t('menu.about_gotou')} link='/' />
        </ul>
        <div className='border-t border-gray-8 py-1'>
          <button
            className='block w-full px-1 py-3 text-left leading-tight hover:underline'
            onClick={handleLogOut}
          >
            {t('menu.logout')}
          </button>
        </div>
      </Dropdown>
    </div>
  );
};

export default ProfileMenuButton;
