import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';

import { createOrder } from 'api/api';

import { useAppSelector } from 'hooks/useAppSelector';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { Service } from 'utils/types';

import {
  addChosenService,
  setIsCalendarOpen,
  clearChosenServices,
  resetState,
} from 'redux/createOrderPageSlice';

import BG from 'images/backgrounds/bg-create-order.webp';

import {
  Button,
  OrderServiceForm,
  SectionHeader,
  toastWithDismiss,
  Wrapper,
} from 'ui';

const CreateOrderPage = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    dateStamp,
    customerName,
    chosenServices,
    isOpenSelectCustomer,
    isCalendarOpen,
    phoneNumber,
    clientId,
    isTimeSelected,
    timeSlotId,
  } = useAppSelector((state) => state.createOrderPage);

  const { uid, role } = useAppSelector((state) => state.user);

  const handleAddOrder = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!dateStamp) {
      toastWithDismiss('Select time', 'error');
      return;
    }

    setIsLoading(true);
    const response = await createOrder(
      {
        clientId,
        specialistId: uid,
        price: chosenServices[0]?.gssDto.price!,
        serviceId: chosenServices[0]?.gssDto.gserviceIdStr!,
        currency: chosenServices[0]?.gssDto.currency!,
        duration: chosenServices[0]?.gssDto.duration!,
        ts: dateStamp,
        timeSlotId,
        role,
      },
      t,
    );
    setIsLoading(false);

    if (response?.orderIdStr) {
      toast.dismiss();
      toastWithDismiss(t('order_success'), 'success');
      dispatch(resetState());
      navigate('/specialist/orders', {
        state: dateStamp,
      });
    }
  };

  const toggleCalendarVisibility = () => {
    if (phoneNumber && customerName && chosenServices.length > 0) {
      dispatch(setIsCalendarOpen(!isCalendarOpen));
    } else {
      toastWithDismiss(
        `Please ${
          !phoneNumber ? 'select your customer' : 'select your service'
        }`,
        'error',
      );
    }
  };

  const handleChooseService = (service: Service) => {
    dispatch(clearChosenServices());
    dispatch(addChosenService(service));
  };

  return (
    <Wrapper noHorizontalPadding flexColumnSpaceBetween>
      <div>
        <SectionHeader title={t('menu.create_order')} wrapperClassName='px-4' />
        <img
          src={BG}
          alt='service background'
          loading='lazy'
          height={225}
          width={450}
          className='mb-5 mt-2 aspect-[2/1] w-full'
        />
        <OrderServiceForm
          onOrderSubmit={handleAddOrder}
          toggleDatePicker={toggleCalendarVisibility}
          handleChooseService={handleChooseService}
        />
      </div>
      {!isOpenSelectCustomer && (
        <div className='mt-5 px-5'>
          <Button
            className='w-full py-4'
            variant='yellow'
            disabled={
              !customerName || chosenServices.length === 0 || !isTimeSelected
            }
            loading={isLoading}
            type='submit'
            form='create-order'
          >
            {t('profile.create_order')}
          </Button>
        </div>
      )}
    </Wrapper>
  );
};

export default CreateOrderPage;
