import { resendCode } from 'api/login';
import { useAppSelector } from 'hooks/useAppSelector';
import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import VerificationInput from 'react-verification-input';
import { Button } from 'ui';

type VerificationFormProps = {
  isLoading: boolean;
  isValid: boolean;
  handleLoginUser: (
    smsCode: string,
    e?: React.FormEvent<HTMLFormElement>,
  ) => void;
  setIsValid: React.Dispatch<React.SetStateAction<boolean>>;
};

const VerificationForm = ({
  isLoading,
  isValid,
  handleLoginUser,
  setIsValid,
}: VerificationFormProps) => {
  const { t } = useTranslation();
  const { role, uid } = useAppSelector((state) => state.user);

  const [isSentTwice, setIsSentTwice] = useState(false);
  const [code, setCode] = useState('');
  const [timer, setTimer] = useState(30);

  const inputRef = useRef<HTMLInputElement>(null);

  const handleChangeInput = (value: string) => {
    setCode(value);
    if (value.length === 6) {
      handleLoginUser(value);
      if (inputRef.current) {
        inputRef.current.blur();
      }
    }
  };

  const handleResendCode = () => {
    setCode('');
    setIsSentTwice(true);
    if (role !== 'guest') resendCode(uid, role);
  };

  useEffect(() => {
    setIsValid(true);
  }, [code, setIsValid]);

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      if (interval) {
        clearInterval(interval);
      }
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [isSentTwice, timer]);

  return (
    <>
      <form
        onSubmit={(e) => handleLoginUser(code, e)}
        className='flex flex-col gap-10'
        id='verification-form'
      >
        <div className='flex max-w-[95vw] justify-center '>
          <VerificationInput
            ref={inputRef}
            validChars='0-9'
            inputProps={{
              inputMode: 'numeric',
              autoComplete: 'one-time-code',
              style: {
                WebkitBoxShadow: '0 0 0px 0px white inset',
                boxShadow: '0 0 0px 0px white inset',
                WebkitTextFillColor: 'transparent',
              },
            }}
            placeholder=''
            length={6}
            classNames={{
              container: 'border-0 ',
              character: `border-0 border-b-2 text-center ${
                isValid ? '' : 'border-error'
              } `,
              characterInactive: 'outline-none bg-white text-primary',
              characterSelected: 'outline-none border-accent text-primary',
            }}
            value={code}
            onChange={handleChangeInput}
            autoFocus={true}
          />
        </div>
        {!isValid && (
          <p className='text-center text-error'>{t('log_in.invalid_sms')}</p>
        )}
        {!isSentTwice && (
          <button
            className='text-accent underline disabled:text-accent-50'
            disabled={timer > 0}
            onClick={handleResendCode}
          >
            {timer || ''} {t('log_in.sms_2')}
          </button>
        )}
      </form>

      <Button
        className='mt-6 w-full py-4'
        variant='yellow'
        type='submit'
        loading={isLoading}
      >
        {t('log_in.continue')}
      </Button>
    </>
  );
};

export default VerificationForm;
