import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, MultilineInput, OrderRatingCard } from 'ui';

type ReviewOrderCardProps = {
  photoURL: string;
  specFullName: string;
  serviceName: string;
  timeOfOrder: number;
  isFavouriteState: boolean;
  handleToggleLikeSpecialist: () => void;
  setStarRating: (rating: number) => void;
  comment: string;
  setComment: (comment: string) => void;
  starRating: number;
  handleAddReview: () => void;
};

const ReviewOrderCard = ({
  timeOfOrder,
  starRating,
  specFullName,
  setStarRating,
  setComment,
  serviceName,
  photoURL,
  isFavouriteState,
  handleToggleLikeSpecialist,
  handleAddReview,
  comment,
}: ReviewOrderCardProps) => {
  const { t } = useTranslation();
  return (
    <>
      <div className='w-full'>
        <OrderRatingCard
          src={photoURL}
          name={specFullName}
          serviceName={serviceName}
          timeOfOrder={timeOfOrder}
          isFavourite={isFavouriteState}
          handleLikeSpecialist={handleToggleLikeSpecialist}
          setStarRating={setStarRating}
        />
      </div>
      <div className='w-full'>
        <MultilineInput
          placeholder='Write a comment'
          className='mb-10'
          wrapperClassName='w-full px-6'
          value={comment}
          onChange={(e: any) => setComment(e.target.value)}
        />
        <Button
          variant='yellow'
          disabled={starRating === 0}
          className='w-full py-4'
          onClick={handleAddReview}
        >
          {t('submit')}
        </Button>
      </div>
    </>
  );
};

export default ReviewOrderCard;
